import styled from 'styled-components';
import { baseColors, ButtonV2 } from '@groupby/ui-components';
import { TableCell, TableHead, TableRow } from '@mui/material';
export const StyledTableHead = styled(TableHead) `
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: ${baseColors.white};
  height: 3.5rem;
`;
export const StyledTableHeadCell = styled(TableCell) `
  && {
    cursor: default;
    width: ${({ width }) => width || 'unset'};
    min-width: ${({ width, minWidth }) => minWidth || width || 'unset'};
    max-width: ${({ width, maxWidth }) => maxWidth || width || 'unset'};

    z-index: 3;
    position: ${({ sticky }) => (sticky ? 'sticky' : 'unset')};
    left: ${({ left }) => (typeof left === 'number' ? `${left}px` : 'unset')};
    background-color: ${baseColors.white};
  }

  &.MuiTableCell-alignCenter [aria-label] {
    width: 100%;
    margin-right: 1rem;
  }
`;
export const StyledBulkActionContainer = styled(TableRow) `
    position: fixed;
    background-color: ${baseColors.background000} !important;
    th {
      background-color: ${baseColors.background000} !important;
    }

    z-index: 4;
    width: ${({ width }) => width};

    th {
      height: 3.5rem;
      box-sizing: border-box;
    }
`;
export const StyledBulkActionButtonsContainer = styled.div `
    padding: 0 0.5rem;
`;
export const StyledTableHeader = styled.div `
  display: flex;
  align-items: center;
  border-left: 1px solid ${baseColors.background000};
  padding-left: 1rem;

  &:last-of-type {
    border-right: 1px solid ${baseColors.background000};
  }
`;
export const StyledFilterButton = styled(ButtonV2) `
  && {
    svg path {
      ${({ $isActive }) => `stroke: ${$isActive ? baseColors.primaryGreen400 : baseColors.primaryBlueMed400}`};
      ${({ $isActive }) => `fill: ${$isActive ? baseColors.primaryGreen400 : baseColors.white}`};
    }

    &:hover {
      svg path {
        ${({ $isActive }) => `fill: ${$isActive ? baseColors.primaryGreen400 : baseColors.white}`};
        ${({ $isActive }) => !$isActive && `stroke: ${baseColors.primaryGreen400}`};
      }
    }
  }
`;
export const StyledSortButton = styled(ButtonV2) `
  && {
    svg {
      ${({ $desc }) => $desc && 'transform: rotate(180deg)'};

      path {
        ${({ $isActive }) => !$isActive && `fill: ${baseColors.primaryBlueMed400}`}};
      }
    }
  }
`;
