import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isGlobalModelType } from '@/search-for-retail/models';
import { denormalize } from '@/utils';
export function merchandisingSelector({ merchandising }) {
    return merchandising;
}
export const managerSelector = (modelType, area) => createSelector(merchandisingSelector, (merchandising) => merchandisingManagerSelector(merchandising, modelType, area));
export const managerModelsSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => managerState && denormalize(managerState));
export const managerModelSelect = (managerState, id) => managerState && managerState.byId[id];
export const managerModelSelector = (modelType, area, id) => createSelector(managerSelector(modelType, area), (managerState) => managerModelSelect(managerState, id));
export const managerModelLoadedSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => managerState && managerState.loaded);
export const isManagerLoadedSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => !!managerState);
export function merchandisingManagerSelector(merchandising, modelType, area) {
    const isGlobal = isGlobalModelType(modelType);
    if (isGlobal) {
        return merchandising.global[modelType];
    }
    const areaModels = merchandising.byArea[modelType];
    if (areaModels && areaModels.allAreas.includes(area)) {
        return areaModels.byArea[area];
    }
}
export function managerModelsSelectorCreator(state) {
    return (modelType, area) => managerModelsSelector(modelType, area)(state) || [];
}
export function managerModelLoadedSelectorCreator(state) {
    return (modelType, area) => managerModelLoadedSelector(modelType, area)(state);
}
export function isManagerLoadedSelectorCreator(state) {
    return (modelType, area) => isManagerLoadedSelector(modelType, area)(state);
}
export const useMerchandisingSelector = () => useSelector(merchandisingSelector);
