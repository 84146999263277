import { baseColors } from '@groupby/ui-components';
export const CHART_HEIGHT = 200;
export const ONE_MILLION = 1000000;
export const percentOptions = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};
export const currencyOptions = {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'USD',
    format: 'en-IN',
};
export const roundNumberOption = {
    maximumSignificantDigits: 3,
};
export const axisLabelStyle = {
    colors: baseColors.primaryBlueMed400,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Roboto',
};
export const chartLabelsParams = {
    LONG_LIST_BREAKPOINT: 20,
    MEDIUM_LIST_BREAKPOINT: 12,
    LONG_LIST_DIVIDER: 4,
    MEDIUM_LIST_DIVIDER: 2,
};
