import { baseColors } from '@groupby/ui-components';
import ListItemButton from '@mui/material/ListItemButton';
import styled from 'styled-components';
export const StyledListItem = styled(ListItemButton) `
  && {
    transition: all .3s ease-in;
    border-left: 4px solid transparent;
    min-height: 48px;
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing(1.35)};
    color: ${baseColors.background000};

    & .MuiSvgIcon-root {
      fill: none;
    }

    &:hover,
    &:focus {
      color: ${baseColors.white};
      background: ${baseColors.white10};

      .MuiTypography-root {
        font-weight: 700;
      }

      svg {
        path, rect {
          stroke: ${baseColors.white};
        }
      }

      div {
        border-color: ${baseColors.primaryBlueAccent400};
      }
    }

    &.active {
      color: ${baseColors.white};
      font-weight: 700;

      .MuiTypography-root {
        font-weight: 700;
      }

      svg {
        path, rect {
          stroke: ${baseColors.white};
        }
      }

      div {
        border-color: ${baseColors.white};
      }
    }
  }

  .MuiListItemText-root {
    flex-shrink: 0;
    margin-left: 1px;
  }

  .MuiListItemIcon-root {
    min-width: 20px;
    margin-right: 20px;
  }

  .hidden {
    display: none;
  }
`;
StyledListItem.displayName = 'StyledListItem';
export const StyledChevronRight = styled.div `
  width: 0.6rem;
  height: 0.6rem;
  border-top: 2px solid ${baseColors.white};
  border-right: 2px solid ${baseColors.white};
  border-radius: 2px;
  transform: rotate(45deg);
`;
StyledChevronRight.displayName = 'StyledChevronRight';
