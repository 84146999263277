import { __awaiter, __rest } from "tslib";
import React, { useCallback, useEffect, useState } from 'react';
import { LocalizationProvider as GBUILocalizationProvider, useLocalization as useGBUILocalization } from '@groupby/ui-components';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import MessageDefinitions from './messages/message-definitions';
import { Localization } from '@/localization';
import { addError, addSuccess, storeUserPreferredLanguage } from '@/store/actions/creators';
import { userIdSelector } from '@/store/selectors';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { updateUserSettings } from '@/search-for-retail/user/user-settings/user-settings.client';
import { ModelType } from '@/constants';
import { useLocale } from './locale.provider';
import { useAuthToken } from '@/auth/hooks/authentication';
import { logError } from '@/utils/logger';
const loadCompiledMessages = (languageCode, featureFlagsService) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const isUnverifiedTranslationsEnabled = featureFlagsService.isFeatureEnabled(FeatureFlag.UnverifiedTranslations);
        const messageVerificationStatusPath = isUnverifiedTranslationsEnabled && languageCode !== Localization.DEFAULT_LANGUAGE_CODE ? 'unverified/' : '';
        const messages = yield import(`./messages/${messageVerificationStatusPath}compiled/${languageCode}.json`);
        return messages.default;
    }
    catch (err) {
        const defaultMessages = yield import(`./messages/compiled/${Localization.DEFAULT_LANGUAGE_CODE}.json`);
        return defaultMessages.default;
    }
});
export const LocalizationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const token = useAuthToken();
    const userId = useSelector(userIdSelector);
    const { locale } = useLocale();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const [messages, setMessages] = useState({});
    useEffect(() => {
        const action = () => __awaiter(void 0, void 0, void 0, function* () {
            setMessages(yield loadCompiledMessages(locale, featureFlagsService));
        });
        void action();
    }, [locale, featureFlagsService]);
    const updateUserLanguageSettings = useCallback((locale) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield updateUserSettings(token, userId, { preferredLanguage: locale });
            dispatch(storeUserPreferredLanguage(locale));
            dispatch(addSuccess({ key: `TASK_${ModelType.USER_SETTINGS.toUpperCase()}_UPDATE_SUCCESS` }));
        }
        catch (e) {
            const errorMessage = `TASK_${ModelType.USER_SETTINGS.toUpperCase()}_UPDATE_ERROR`;
            logError(errorMessage, e);
            dispatch(addError({ key: errorMessage }));
        }
    }), [dispatch, token, userId]);
    const setLocale = (locale) => updateUserLanguageSettings(locale);
    return (<GBUILocalizationProvider locale={locale} defaultLocale={Localization.DEFAULT_LANGUAGE_CODE} messages={messages} setLocale={setLocale}>
      {children}
    </GBUILocalizationProvider>);
};
export const useLocalization = () => {
    const _a = useGBUILocalization(), { formatMessage } = _a, rest = __rest(_a, ["formatMessage"]);
    return Object.assign(Object.assign({}, rest), { formatMessage: (message) => {
            const messageDefinition = MessageDefinitions[message.key];
            if (isNil(messageDefinition)) {
                return message.key;
            }
            return formatMessage(messageDefinition, message.values);
        } });
};
