import { DateFormatDefaults as GBUIDateFormatDefaults } from '@groupby/ui-components';
export var Localization;
(function (Localization) {
    Localization.DateFormatDefaults = GBUIDateFormatDefaults;
    Localization.TimeFormat = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
    };
    Localization.DateTimeFormat = Object.assign(Object.assign({}, Localization.DateFormatDefaults), Localization.TimeFormat);
    Localization.SupportedLanguages = {
        ENGLISH_US: 'en-US',
        SIMPLIFIED_CHINESE: 'zh',
        SPANISH: 'es',
        FRENCH: 'fr',
        GERMAN: 'de',
        ITALIAN: 'it',
        DUTCH: 'nl',
        JAPANESE: 'ja',
        PORTUGUESE_BRAZIL: 'pt-BR',
    };
    Localization.QALanguage = {
        EMOJI: 'qem',
    };
    Localization.DEFAULT_LANGUAGE_CODE = Localization.SupportedLanguages.ENGLISH_US;
    Localization.ShortDateFormat = {
        month: 'short',
        day: 'numeric',
    };
    Localization.ShortDateUTCFormat = Object.assign(Object.assign({}, Localization.ShortDateFormat), { timeZone: 'UTC' });
    Localization.DateUTCFormatDefaults = Object.assign(Object.assign({}, Localization.DateFormatDefaults), { timeZone: 'UTC' });
})(Localization || (Localization = {}));
export var CURRENCY;
(function (CURRENCY) {
    CURRENCY["AUD"] = "AUD";
    CURRENCY["EUR"] = "EUR";
    CURRENCY["GBP"] = "GBP";
    CURRENCY["JPY"] = "JPY";
    CURRENCY["USD"] = "USD";
})(CURRENCY || (CURRENCY = {}));
export const compactNumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    notation: 'compact',
};
export const standardCurrencyFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 0,
};
export const getCodeToLanguage = () => ({
    [Localization.SupportedLanguages.ENGLISH_US]: 'ENGLISH',
    [Localization.SupportedLanguages.SIMPLIFIED_CHINESE]: 'SIMPLIFIED_CHINESE',
    [Localization.SupportedLanguages.SPANISH]: 'SPANISH',
    [Localization.SupportedLanguages.PORTUGUESE_BRAZIL]: 'PORTUGUESE_BRAZIL',
    [Localization.SupportedLanguages.FRENCH]: 'FRENCH',
    [Localization.SupportedLanguages.GERMAN]: 'GERMAN',
    [Localization.SupportedLanguages.ITALIAN]: 'ITALIAN',
    [Localization.SupportedLanguages.DUTCH]: 'DUTCH',
    [Localization.SupportedLanguages.JAPANESE]: 'JAPANESE',
});
export const YEAR_MONTH_DAY_FORMAT = { year: 'numeric', month: 'numeric', day: 'numeric' };
