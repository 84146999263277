import React from 'react';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import { StyledListItem } from './navigation-drawer-logo.styles';
export const NavigationDrawerLogo = ({ text, icon, onLogoClick, className, linkDisabled, }) => {
    const handleItemClick = () => {
        onLogoClick === null || onLogoClick === void 0 ? void 0 : onLogoClick();
    };
    const listItemClassNames = classNames({
        collapsed: false,
        linkDisabled,
    }, className);
    return (<StyledListItem className={listItemClassNames} button key={text} onClick={linkDisabled ? null : handleItemClick} component={Link} underline="none">
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text}/>
    </StyledListItem>);
};
export default NavigationDrawerLogo;
