import React from 'react';
import { baseColors } from '@groupby/ui-components';
import { getCodeToLanguage, LocalizedMessage } from '@/localization';
import { StyledMenu, StyledMenuItem } from './menu.styles';
export const LanguageSelectionMenu = ({ handleClose, languageMenuAnchorEl, selectLanguage, selectedLanguage, }) => {
    const languageOptions = Object.entries(getCodeToLanguage()).map(([code, language]) => ({
        label: <LocalizedMessage messageKey={language}/>,
        code,
    }));
    return (<StyledMenu getContentAnchorEl={null} anchorEl={languageMenuAnchorEl} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }} open={Boolean(languageMenuAnchorEl)} onClose={handleClose}>
      {languageOptions.map((language) => (<StyledMenuItem button key={language.code} copy={language.label} disabled={selectedLanguage === language.code} onClick={() => {
        selectLanguage(language.code);
        handleClose();
    }} color={baseColors.primaryBlueMed500}/>))}
    </StyledMenu>);
};
export default LanguageSelectionMenu;
