import React from 'react';
import { ButtonV2, Icon } from '@groupby/ui-components';
import { StyledListItem, StyledListItemCheckbox } from './table-customize-view.styles';
export const CustomizeViewListItem = ({ item, onDrag, checked, onCheck, isDraggable, }) => {
    const handleDrag = (e) => {
        e.stopPropagation();
        if (isDraggable) {
            onDrag(item, e, { draggable: true });
        }
        else {
            onDrag(item, e, { draggable: false });
        }
    };
    const handleCheck = (e) => {
        onCheck(item, e.target.checked);
    };
    return (<StyledListItem>
      <ButtonV2 onMouseDown={handleDrag} disabled={!isDraggable} icon={<Icon variant="drag"/>}/>
      <label>
        <StyledListItemCheckbox className="checkbox" type="checkbox" disabled={item.static} checked={checked} onChange={handleCheck}/>
        <span>{item.label}</span>
      </label>
    </StyledListItem>);
};
