import { __decorate } from "tslib";
import React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const MAX_ITEM_LENGTH = 14;
export const styles = {
    root: {
        width: '100%',
        '&$special': {
            color: '#000',
            fontWeight: 500,
        },
    },
    special: {},
};
let SelectLabel = class SelectLabel extends React.PureComponent {
    render() {
        const { special, classes, children } = this.props;
        return (<Typography noWrap className={classNames(classes.root, { [classes.special]: special })}>
        {children}
      </Typography>);
    }
};
SelectLabel = __decorate([
    (withStyles(styles))
], SelectLabel);
export default SelectLabel;
