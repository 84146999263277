import { __awaiter, __rest } from "tslib";
import { identity } from 'ramda';
import fetch from './fetch';
import * as APIUtils from './utils';
import { IS_NON_PUBLIC_ENV, S4R_API_ENV, S4R_API_PREFIX } from '@/config/global';
import { ENRICH_ONLY_ENV_PREFIX, isEnrichOnlyEnv } from '@/utils/environment';
import { activeAreaSelector, activeCollectionSelector } from '@/store/selectors/session';
import { getTenantName } from '@/utils';
import { getStore } from '@/store/store-proxy';
export var ErrorTypes;
(function (ErrorTypes) {
    ErrorTypes["AbortError"] = "AbortError";
})(ErrorTypes || (ErrorTypes = {}));
export const JSON_HEADERS = { 'Content-Type': 'application/json' };
const buildSessionHeaders = () => {
    const headers = {};
    const store = getStore();
    const state = store.getState();
    const areaName = activeAreaSelector(state);
    const collectionName = activeCollectionSelector(state);
    if (areaName)
        headers['X-Groupby-Area-Name'] = areaName;
    if (collectionName)
        headers['X-Groupby-Collection-Name'] = collectionName;
    return headers;
};
export const buildCustomerHeaders = () => ({ 'X-Groupby-Customer-Id': getTenantName() });
export const buildHeaders = () => (Object.assign(Object.assign({}, buildCustomerHeaders()), buildSessionHeaders()));
export function s4rAuthenticated(token, config) {
    return __awaiter(this, void 0, void 0, function* () {
        const { url, method, body: rawBody, files, responseType, headers: rawHeaders = Object.assign({}, buildHeaders()), expiry = false, skipCache, signal, } = config;
        let headers = rawHeaders;
        let body = null;
        if (files) {
            body = yield APIUtils.mapFilesToMultipartBody(files);
        }
        else if (rawBody) {
            headers = Object.assign(Object.assign(Object.assign({}, JSON_HEADERS), buildHeaders()), headers);
            body = typeof rawBody === 'string' ? rawBody : JSON.stringify(rawBody);
        }
        const request = {
            method,
            skipCache,
            expiry,
            responseType,
            headers: Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${token}` }),
            body,
            signal,
        };
        return fetch(url, request)
            .then(APIUtils.extractResponse())
            .then(APIUtils.extractTokens);
    });
}
export function authenticated(token, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, method = 'GET', body: rawBody, files, parse, headers: rawHeaders = {}, expiry, skipCache, } = cb(...args);
        let headers = rawHeaders;
        let body = null;
        if (files) {
            headers = Object.keys(headers)
                .filter((key) => key.toLowerCase() !== 'content-type')
                .reduce((filtered, key) => {
                filtered[key] = headers[key];
                return filtered;
            }, {});
            body = yield APIUtils.mapFilesToMultipartBody(files);
        }
        else if (rawBody) {
            headers = Object.assign(Object.assign({}, headers), JSON_HEADERS);
            body = typeof rawBody === 'string' ? rawBody : JSON.stringify(rawBody);
        }
        const request = {
            method,
            skipCache,
            expiry,
            headers: Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${token}` }),
            body: body,
        };
        return fetch(url, request)
            .then(APIUtils.extractResponse(parse))
            .then(APIUtils.extractTokens);
    });
}
export function adminAuthenticated(clientKey, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, headers = {}, expiry, skipCache, } = cb(...args);
        const request = {
            headers,
            skipCache,
            expiry,
            method: 'POST',
            body: JSON.stringify({ clientKey }),
        };
        return fetch(url, request).then(APIUtils.extractResponse());
    });
}
/* eslint-disable @typescript-eslint/require-await */
export function mapFilesToMultipartBody(files) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        for (const key of Object.keys(files)) {
            formData.append(key, files[key]);
        }
        return formData;
    });
}
export function extractResponse(parse = identity) {
    return (_a) => { var _b; return __awaiter(this, void 0, void 0, function* () {
        var { result } = _a, response = __rest(_a, ["result"]);
        const rawResult = ((_b = result) === null || _b === void 0 ? void 0 : _b.result) || result;
        const parsedResult = parse ? parse(rawResult) : rawResult;
        return Object.assign(Object.assign({}, response), { result: parsedResult });
    }); };
}
export function extractProvidersList(response) {
    var _a;
    return (_a = response === null || response === void 0 ? void 0 : response.result) === null || _a === void 0 ? void 0 : _a.result;
}
export function extractTokens(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const { access_token: token, refresh_token: refreshToken } = response.result || {};
        return Object.assign(Object.assign(Object.assign({}, response), (token ? { token } : {})), (refreshToken ? { refreshToken } : {}));
    });
}
/* eslint-enable @typescript-eslint/require-await */
export function arrayParser(parse) {
    return (result) => {
        if (parse) {
            return Array.isArray(result) ? result.map(parse).filter(Boolean) : parse(result);
        }
        return result;
    };
}
export const getEnvSubdomainFromHostname = (hostname) => {
    const [serviceSubdomain, envSubdomain = ''] = hostname.split('.');
    if (serviceSubdomain.includes('-atlas')) {
        return serviceSubdomain;
    }
    return envSubdomain;
};
export const getAPIUrl = (hostname) => {
    if (IS_NON_PUBLIC_ENV) {
        return S4R_API_PREFIX;
    }
    if (isEnrichOnlyEnv(hostname)) {
        const ccapiHostname = hostname.replace(ENRICH_ONLY_ENV_PREFIX, 'ccapi-');
        return `https://${ccapiHostname}/ccapi`;
    }
    const envSubdomain = getEnvSubdomainFromHostname(hostname);
    return `https://ccapi.${envSubdomain}.groupbycloud.com/ccapi`;
};
export const getIngestAPIUrl = (hostname) => {
    if (IS_NON_PUBLIC_ENV) {
        return `https://ingest-${S4R_API_ENV}.groupbycloud.com/api`;
    }
    const envSubdomain = getEnvSubdomainFromHostname(hostname);
    return `https://ingest-${envSubdomain}.groupbycloud.com/api`;
};
export const getSearchAPIUrl = (hostname) => {
    if (IS_NON_PUBLIC_ENV) {
        return `https://search.${S4R_API_ENV}.groupbycloud.com/api/search`;
    }
    const envSubdomain = getEnvSubdomainFromHostname(hostname);
    return `https://search.${envSubdomain}.groupbycloud.com/api/search`;
};
export const getCategoriesAPIUrl = (hostname) => {
    if (IS_NON_PUBLIC_ENV) {
        return `https://cm.${S4R_API_ENV}.groupbycloud.com/api/megamenus`;
    }
    const envSubdomain = getEnvSubdomainFromHostname(hostname);
    return `https://cm.${envSubdomain}.groupbycloud.com/api/megamenus`;
};
export const getRecsAPIUrl = (hostname) => {
    if (IS_NON_PUBLIC_ENV) {
        return `https://recsapi.${S4R_API_ENV}.groupbycloud.com/api/recommendation`;
    }
    const envSubdomain = getEnvSubdomainFromHostname(hostname);
    return `https://recsapi.${envSubdomain}.groupbycloud.com/api/recommendation`;
};
export const isRequestAborted = (error) => error.name === ErrorTypes.AbortError;
