import { __awaiter } from "tslib";
// REFACTORING NEEDED(slpit into smaller components, custom hooks?)
import React, { useEffect, useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Accordion } from '@groupby/ui-components';
import { useDispatch } from 'react-redux';
import { filterAllReleaseNotesBySearchResults, initSearchIndex, mapReleaseNotesContentItemMapToSearchDocumentList, searchPhrase, } from '@/pages/release-notes/model/search-engine';
import { PageTitle, SearchBar } from '@/pages/release-notes/ui';
import { HighlightedText, StyledReleaseNotesCard, StyledReleaseNotesPageContainer, TypographyShowResults, } from '@/pages/release-notes/release-notes.styles';
import { fetchReleaseNoteMarkdown, getReleaseNotes } from '@/pages/release-notes/api/release-notes.client';
import { useAuthToken } from '@/auth/hooks/authentication';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { logError } from '@/utils/logger';
import { useReleaseNotesItemsSelector } from '@/store/selectors';
import { cacheReleaseNotes } from '@/store/actions/creators';
import Loading from '@/components/loading';
import { renderBoldText, useLocalization } from '@/localization';
export const ReleaseNotes = () => {
    const [searchValue, setSearchValue] = useState('');
    const [dataDescriptors, setDataDescriptors] = useState([]);
    const token = useAuthToken();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const storedItems = useReleaseNotesItemsSelector();
    const dispatch = useDispatch();
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [openState, setOpenState] = useState({});
    const { formatMessage: t, formatNumber } = useLocalization();
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const releaseNotesData = yield getReleaseNotes(token);
                setDataDescriptors(releaseNotesData);
            }
            catch (error) {
                logError('Error fetching release notes:', error);
            }
        });
        setItemsLoading(true);
        void fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    useEffect(() => {
        if (dataDescriptors.length) {
            const syncCache = () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const itemsToFetch = [];
                    dataDescriptors.forEach((data) => {
                        var _a;
                        if (!((_a = storedItems.byId[data.fileName]) === null || _a === void 0 ? void 0 : _a.content)) {
                            itemsToFetch.push({ data, promise: fetchReleaseNoteMarkdown(token, data.fileName) });
                        }
                    });
                    if (itemsToFetch.length) {
                        const values = yield Promise.all(itemsToFetch.map((item) => item.promise));
                        itemsToFetch.forEach((item, index) => {
                            item.data.content = values[index];
                        });
                        return itemsToFetch;
                    }
                }
                catch (error) {
                    logError('Error fetching release notes:', error);
                }
            });
            syncCache().then((items) => {
                if (items === null || items === void 0 ? void 0 : items.length) {
                    const newReleaseNotes = items.map((i) => i.data);
                    dispatch(cacheReleaseNotes(newReleaseNotes));
                }
            }).catch((e) => {
                var _a;
                logError((_a = e === null || e === void 0 ? void 0 : e.message) !== null && _a !== void 0 ? _a : 'release-notes.tsx -> error');
            });
        }
    }, [dataDescriptors, dispatch, storedItems, token]);
    useEffect(() => {
        if (storedItems.allIds.length) {
            initSearchIndex(mapReleaseNotesContentItemMapToSearchDocumentList(storedItems));
            const newFilteredItems = filterAllReleaseNotesBySearchResults(storedItems, '', searchPhrase);
            setFilteredItems(newFilteredItems);
            setItemsLoading(false);
        }
    }, [storedItems]);
    useEffect(() => {
        const initialOpenState = filteredItems.reduce((acc, item, index) => {
            acc[item.fileName] = !!searchValue || index === 0;
            return acc;
        }, {});
        setOpenState(initialOpenState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredItems]);
    const handleSearch = () => {
        const newFilteredItems = filterAllReleaseNotesBySearchResults(storedItems, searchValue, searchPhrase);
        setFilteredItems(newFilteredItems);
    };
    const toggleAccordion = (fileName) => {
        setOpenState((prevState) => (Object.assign(Object.assign({}, prevState), { [fileName]: !prevState[fileName] })));
    };
    const highlightText = (text, phrase) => {
        if (!phrase)
            return text;
        const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const escapedPhrase = escapeRegExp(phrase);
        const regex = new RegExp(`(${escapedPhrase})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => (regex.test(part) ? <HighlightedText key={index}>{part}</HighlightedText> : part));
    };
    return (<React.Fragment>
      {featureFlagsService.isFeatureEnabled(FeatureFlag.EnableReleaseNotes) && (<React.Fragment>
          <StyledReleaseNotesPageContainer className="release-notes-page-container">
            <PageTitle />
            <div className="release_notes_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} onSubmit={handleSearch}/>
              <TypographyShowResults>
                {t({ key: 'REF_APP_TOTAL_RESULTS', values: { total: formatNumber(filteredItems === null || filteredItems === void 0 ? void 0 : filteredItems.length), highlight: renderBoldText } })}
              </TypographyShowResults>
              {itemsLoading
        ? <Loading isLoading/>
        : (filteredItems === null || filteredItems === void 0 ? void 0 : filteredItems.length) ? filteredItems.map((item) => {
            var _a, _b;
            return (<StyledReleaseNotesCard key={item.releaseName}>
                    <Accordion isOpened={openState[item.fileName]} onToggle={() => toggleAccordion(item.fileName)} heading={<span>
                          Date: {item.isReleaseDateHighlighted ? (highlightText(item.releaseDate, searchValue))
                : (item.releaseDate)} | Name: {item.isReleaseNameHighlighted
                ? (highlightText(item.releaseName, searchValue))
                : (item.releaseName)}
                        </span>}>
                      <hr />
                      <MarkdownPreview wrapperElement={{ 'data-color-mode': 'light' }} source={(((_a = storedItems.byId[item.fileName]) === null || _a === void 0 ? void 0 : _a.highlightedContent) || ((_b = storedItems.byId[item.fileName]) === null || _b === void 0 ? void 0 : _b.content)) || 'Loading...'}/>
                    </Accordion>
                  </StyledReleaseNotesCard>);
        }) : <p>No data found</p>}
            </div>
          </StyledReleaseNotesPageContainer>
        </React.Fragment>)}
    </React.Fragment>);
};
