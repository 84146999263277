import { BackgroundCard } from '@groupby/ui-components';
import styled from 'styled-components';
export const StyledFormSection = styled(BackgroundCard) `
  && {
    padding-bottom: 1rem;
    &.MuiPaper-root {
      box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px 0, rgba(0, 0, 0, 0.05) 0 0.1px 0.3px 0;
    }

    &:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing(5)};
      ${({ $noContent }) => ($noContent && 'cursor: pointer;')};
    }

    .MuiCardHeader-root {
      ${({ $noContent }) => ($noContent && 'padding-bottom: 14px;')};
      ${({ $hideable }) => ($hideable ? 'cursor: pointer;' : 'cursor: default')};
      }
    }

    .MuiCardContent-root {
      padding-top: ${(props) => (props.title || props.headerDivider ? props.theme.spacing(3) : props.theme.spacing(4))};
      ${({ $noContent }) => ($noContent && 'display: none')};
    }

    .MuiDivider-fullWidth {
      margin-top: ${({ theme }) => theme.spacing(3)};
    }

    .MuiCardHeader-title {
      text-transform: uppercase;
      padding-bottom: 0;
    }

    .MuiCardHeader-subheader {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }

    & .field-heading {
      margin-bottom: 1rem;
    }

    & .field {
      margin-bottom: ${({ theme }) => theme.spacing(4)};
    }
  }
`;
export default StyledFormSection;
