import * as FeatureFlags from './feature-flags';
import { IS_DEV_ENV, IS_PRODUCTION_ENV, IS_STAGING_ENV } from './global';
import { extractCustomerName } from './utils';
// The following regex has negative lookahead to handle "cc-qa2" correctly.
// This will fail for any legitimate customer ID that starts with "qa".
// See also: src/config/global.ts
export const COOKIE_NAME_S4R = 's4r_enabled';
export const S4R_QUERY_PARAM = 's4r';
export function hasBetaAccess() {
    return !IS_PRODUCTION_ENV || IS_STAGING_ENV || IS_DEV_ENV;
}
export function isCustomer(name) {
    return extractCustomerName(window.location.hostname) === name;
}
export function isSearchForRetailUrl() {
    const subdomain = window.location.hostname.split('.')[0];
    return subdomain === 'cc'
        || subdomain.endsWith('-atlas'); // deprecated
}
export function isAnalyticsChartCustomer() {
    return (hasBetaAccess()
        || isCustomer('swansonhealth')
        || isCustomer('bestbuyca')
        || isCustomer('vitaminshoppe')
        || isCustomer('buildcom'));
}
export function isINavCustomer() {
    return (FeatureFlags.hasBetaAccess()
        || FeatureFlags.isCustomer('bedbathbeyond')
        || FeatureFlags.isCustomer('cvshealth')
        || FeatureFlags.isCustomer('lovelyskin')
        || FeatureFlags.isCustomer('connection')
        || FeatureFlags.isCustomer('signet'));
}
