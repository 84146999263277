export const WRAPPED_COMPONENT_KEY = 'wrappedComponent';
export const DEFAULT_RELEVANCY = 'Default Relevancy';
export const SESSION_EXPIRATION_TIMEOUT = 1800000;
export const SESSION_INACTIVE_TIMEOUT = 300000;
export const DATE_FORMAT_HOUR = 'YYYYMMDDHH';
export const DEFAULT_CACHE_TTL = 600000;
export const SEARCH_QUERY_UPDATE_DELAY = 500;
export var Role;
(function (Role) {
    Role.MERCHANDISING = 'merchandising';
    Role.QUERY_REWRITE = 'queryRewrite';
    Role.ENGINE_CONFIGURATION = 'engineConfiguration';
    Role.ADMIN = 'admin';
    Role.ANALYTICS = 'analytics';
})(Role || (Role = {}));
// user roles
export var SearchForRetailRole;
(function (SearchForRetailRole) {
    SearchForRetailRole["SuperAdmin"] = "SUPERADMIN";
    SearchForRetailRole["Admin"] = "ADMIN";
    SearchForRetailRole["Merchandiser"] = "MERCHANDISER";
    SearchForRetailRole["TenantAdmion"] = "TENANTADMIN";
})(SearchForRetailRole || (SearchForRetailRole = {}));
// area permission roles
export var SearchForRetailPermissionRole;
(function (SearchForRetailPermissionRole) {
    SearchForRetailPermissionRole["Guest"] = "GUEST";
    SearchForRetailPermissionRole["Merchandiser"] = "MERCHANDISER";
})(SearchForRetailPermissionRole || (SearchForRetailPermissionRole = {}));
export const EDITING_ALLOWED_PERMISSION_ROLES = [SearchForRetailPermissionRole.Merchandiser];
export const ADMIN_ROLE_NAME = SearchForRetailPermissionRole.Merchandiser;
export var ModelType;
(function (ModelType) {
    ModelType.HOME_DASHBOARDS = 'home_dashboards';
    ModelType.RULE_EXPERIMENTS = 'rule_experiments';
    ModelType.ANALYTICS = 'analytics';
    ModelType.RULE = 'rule';
    ModelType.ZONE = 'zone';
    ModelType.BIASING_PROFILE = 'biasingProfile';
    ModelType.REDIRECT = 'redirect';
    ModelType.TEMPLATE = 'template';
    ModelType.PREVIEW_WRAPPER = 'previewWrapper';
    ModelType.NAVIGATION = 'navigation';
    ModelType.AREA = 'area';
    ModelType.USER = 'user';
    ModelType.USER_SETTINGS = 'user_settings';
    ModelType.PERMISSION_ROLE = 'permissionRole';
    ModelType.TAG = 'tag';
    ModelType.KEY = 'key';
    ModelType.FILTER = 'filter';
    ModelType.AUDIT_LOG = 'auditLog';
    ModelType.PROJECT_CONFIGURATION = 'projectConfiguration';
    ModelType.TENANT = 'tenant';
    ModelType.RECOMMENDATIONS = 'recommendations';
    ModelType.INGESTION_TASKS = 'tasks';
    ModelType.FILTER_SETS = 'filterSets';
    ModelType.ATTRIBUTES = 'attributes';
    ModelType.LINGUISTIC_CONTROLS = 'linguisticControls';
    ModelType.EVALUATE = 'evaluate';
    ModelType.PRODUCT_RECOMMENDATIONS = 'productRecommendations';
    ModelType.SITE = 'site';
    ModelType.AUTOCOMPLETE = 'autocomplete';
    ModelType.RECS_MODEL = 'recsModel';
    ModelType.CATEGORIES = 'categories';
    ModelType.IMAGE_PATH = 'imagePath';
    ModelType.SEARCH_DATA_QUALITY = 'dataQuality';
    ModelType.PIPELINE_HEALTH = 'pipelineHealth';
    ModelType.RELEASE_NOTES = 'releaseNotes';
    ModelType.PLACEMENT = 'placement';
})(ModelType || (ModelType = {}));
export var ChartType;
(function (ChartType) {
    ChartType.RECORD_COUNT = 'recordCount';
    ChartType.QPS = 'qps';
})(ChartType || (ChartType = {}));
export var ChartName;
(function (ChartName) {
    ChartName["QPS"] = "qps";
    ChartName["TOTAL_QUERIES"] = "totalQueries";
    ChartName["MONTHLY_SNAPSHOT"] = "monthlySnapshot";
    ChartName["QPS_DISTRIBUTION"] = "qpsDistribution";
    ChartName["SEARCH_CONVERSION_FUNNEL"] = "searchConversionFunnel";
    ChartName["QUERY_VOLUME"] = "queryVolume";
    ChartName["ERRORS"] = "errors";
    ChartName["API_RESPONSE_TIME"] = "apiResponseTime";
})(ChartName || (ChartName = {}));
export var Routing;
(function (Routing) {
    Routing.CREATE_MODEL_SEGMENT = '_create';
    Routing.MERCHANDISING_PATH = '/merchandising';
    Routing.ENRICH_PATH = '/enrich';
    Routing.RELEASE_NOTES_PATH = '/release-notes';
    Routing.MODEL_MANAGER_PATH = `${Routing.MERCHANDISING_PATH}/:model/:collection?/:area?`;
    Routing.MODEL_CREATOR_PATH = `${Routing.MERCHANDISING_PATH}/:model/:collection?/:area?/${Routing.CREATE_MODEL_SEGMENT}`;
    Routing.LOGIN_PATH = '/login';
    let SectionPath;
    (function (SectionPath) {
        SectionPath.SITE = 'site';
        SectionPath.RECALL = 'recall';
        SectionPath.CONFIG = 'config';
        SectionPath.ADMIN = 'admin';
        SectionPath.ANALYTICS = 'analytics';
        SectionPath.AUDIT_LOG = 'auditLog';
        SectionPath.RELEASE_NOTES = 'releaseNotes';
        SectionPath.TEMPLATE = 'template';
        SectionPath.RECOMMENDATIONS = 'recommendations';
        SectionPath.TAG_MANAGEMENT = 'tag-management';
        SectionPath.ATTRIBUTE_MANAGEMENT = 'attribute-management';
        SectionPath.LINGUISTIC_CONTROLS = 'linguistic-controls';
        SectionPath.MERCHANDISING = 'merchandising';
        SectionPath.PRODUCT_RECOMMENDATIONS = 'product-recommendations';
        SectionPath.ENRICH = 'enrich';
    })(SectionPath = Routing.SectionPath || (Routing.SectionPath = {}));
    Routing.MODEL_PATHS = {
        [ModelType.RULE]: 'rules',
        [ModelType.RULE_EXPERIMENTS]: 'ruleExperiments',
        [ModelType.ZONE]: 'zones',
        [ModelType.BIASING_PROFILE]: 'biasingProfiles',
        [ModelType.REDIRECT]: 'redirects',
        [ModelType.TEMPLATE]: 'templates',
        [ModelType.PREVIEW_WRAPPER]: 'previewWrapper',
        [ModelType.NAVIGATION]: 'navigations',
        [ModelType.AREA]: 'areas',
        [ModelType.USER]: 'users',
        [ModelType.PERMISSION_ROLE]: 'users/roles',
        [ModelType.USER_SETTINGS]: 'users/settings',
        [ModelType.TAG]: 'tags',
        [ModelType.KEY]: 'keys',
        [ModelType.FILTER]: 'filters',
        [ModelType.AUDIT_LOG]: 'auditLog',
        [ModelType.RELEASE_NOTES]: 'releaseNotes',
        [ModelType.ANALYTICS]: 'analytics',
        [ModelType.RECOMMENDATIONS]: 'recommendations',
        [ModelType.INGESTION_TASKS]: 'tasks',
        [ModelType.TENANT]: 'tenant',
        [ModelType.PROJECT_CONFIGURATION]: 'projectConfiguration',
        [ModelType.FILTER_SETS]: 'filterSets',
        [ModelType.ATTRIBUTES]: 'attributes',
        [ModelType.LINGUISTIC_CONTROLS]: 'linguisticControls',
        [ModelType.HOME_DASHBOARDS]: 'merchandising',
        [ModelType.EVALUATE]: 'evaluate',
        [ModelType.PRODUCT_RECOMMENDATIONS]: 'productRecommendations',
        [ModelType.SITE]: 'sites',
        [ModelType.AUTOCOMPLETE]: 'autocomplete',
        [ModelType.RECS_MODEL]: 'recsModel',
        [ModelType.CATEGORIES]: 'categories',
        [ModelType.IMAGE_PATH]: 'imagePath',
        [ModelType.SEARCH_DATA_QUALITY]: 'dataQuality',
        [ModelType.PIPELINE_HEALTH]: 'pipelineHealth',
    };
    let Section;
    (function (Section) {
        let Merchandising;
        (function (Merchandising) {
            Merchandising.ANALYTICS = 'analytics';
            Merchandising.TEMPLATE = 'template';
            Merchandising.LINGUISTIC_CONTROLS = 'linguistic-controls';
            Merchandising.PRODUCT_RECOMMENDATIONS = 'product-recommendations';
        })(Merchandising = Section.Merchandising || (Section.Merchandising = {}));
    })(Section = Routing.Section || (Routing.Section = {}));
})(Routing || (Routing = {}));
export var SearchForRetail;
(function (SearchForRetail) {
    let BiasingStrategyStrength;
    (function (BiasingStrategyStrength) {
        BiasingStrategyStrength[BiasingStrategyStrength["ABSOLUTE_DECREASE"] = 0] = "ABSOLUTE_DECREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["STRONG_DECREASE"] = 1] = "STRONG_DECREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["MEDIUM_DECREASE"] = 2] = "MEDIUM_DECREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["WEAK_DECREASE"] = 3] = "WEAK_DECREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["LEAVE_UNCHANGED"] = 4] = "LEAVE_UNCHANGED";
        BiasingStrategyStrength[BiasingStrategyStrength["WEAK_INCREASE"] = 5] = "WEAK_INCREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["MEDIUM_INCREASE"] = 6] = "MEDIUM_INCREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["STRONG_INCREASE"] = 7] = "STRONG_INCREASE";
        BiasingStrategyStrength[BiasingStrategyStrength["ABSOLUTE_INCREASE"] = 8] = "ABSOLUTE_INCREASE";
    })(BiasingStrategyStrength = SearchForRetail.BiasingStrategyStrength || (SearchForRetail.BiasingStrategyStrength = {}));
    SearchForRetail.BIASING_STRATEGY_STRENGTH_LOCALIZED_MESSAGE_KEY = {
        [BiasingStrategyStrength.ABSOLUTE_DECREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_ABSOLUTEDECREASE',
        [BiasingStrategyStrength.STRONG_DECREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_STRONGDECREASE',
        [BiasingStrategyStrength.MEDIUM_DECREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_MEDIUMDECREASE',
        [BiasingStrategyStrength.WEAK_DECREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_WEAKDECREASE',
        [BiasingStrategyStrength.LEAVE_UNCHANGED]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_UNCHANGED',
        [BiasingStrategyStrength.WEAK_INCREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_WEAKINCREASE',
        [BiasingStrategyStrength.MEDIUM_INCREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_MEDIUMINCREASE',
        [BiasingStrategyStrength.STRONG_INCREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_STRONGINCREASE',
        [BiasingStrategyStrength.ABSOLUTE_INCREASE]: 'BIASING_STRATEGY_FORM_INFLUENCESLIDER_ABSOLUTEINCREASE',
    };
    let TriggerType;
    (function (TriggerType) {
        TriggerType["CONTAINS"] = "CONTAINS";
        TriggerType["ENDS_WITH"] = "ENDS_WITH";
        TriggerType["REGEX"] = "REGEX";
        TriggerType["STARTS_WITH"] = "STARTS_WITH";
        TriggerType["MATCHES"] = "MATCHES";
        TriggerType["CUSTOM_PARAMETER"] = "CUSTOM_PARAMETER";
        TriggerType["NAVIGATION_RANGE"] = "RANGE";
        TriggerType["NAVIGATION_SELECTED"] = "NAVIGATION_SELECTED";
        TriggerType["NAVIGATION_VALUE"] = "VALUE";
    })(TriggerType = SearchForRetail.TriggerType || (SearchForRetail.TriggerType = {}));
    let FilterType;
    (function (FilterType) {
        FilterType["FILTERED_BY"] = "FilteredBy";
        FilterType["SEARCH"] = "Search";
        FilterType["RANGE"] = "Range";
        FilterType["EXCLUSION"] = "Exclusion";
    })(FilterType = SearchForRetail.FilterType || (SearchForRetail.FilterType = {}));
    SearchForRetail.triggerMap = {
        [TriggerType.CONTAINS]: 'queryPatternTriggers',
        [TriggerType.ENDS_WITH]: 'queryPatternTriggers',
        [TriggerType.REGEX]: 'queryPatternTriggers',
        [TriggerType.STARTS_WITH]: 'queryPatternTriggers',
        [TriggerType.MATCHES]: 'queryPatternTriggers',
        [TriggerType.CUSTOM_PARAMETER]: 'customParameterTriggers',
        [TriggerType.NAVIGATION_RANGE]: 'selectedRefinementTriggers',
        [TriggerType.NAVIGATION_SELECTED]: 'selectedRefinementTriggers',
        [TriggerType.NAVIGATION_VALUE]: 'selectedRefinementTriggers',
    };
    SearchForRetail.MODEL_TYPE_DISPLAY_NAMES_LOCALIZED_MESSAGE_KEY = {
        [ModelType.ANALYTICS]: 'ANALYTICS',
        [ModelType.RULE]: 'RULE',
        [ModelType.BIASING_PROFILE]: 'BIASING_STRATEGY',
        [ModelType.NAVIGATION]: 'DYNAMIC_NAVIGATION',
        [ModelType.REDIRECT]: 'REDIRECT',
        [ModelType.ZONE]: 'ZONE',
        [ModelType.TEMPLATE]: 'TEMPLATE',
        [ModelType.AREA]: 'AREA',
        [ModelType.USER]: 'USER',
        [ModelType.PROJECT_CONFIGURATION]: 'PROJECT_CONFIGURATION',
        [ModelType.TENANT]: 'TENANT',
        [ModelType.TAG]: 'TAG',
        [ModelType.FILTER_SETS]: 'FILTER_SETS',
        [ModelType.ATTRIBUTES]: 'ATTRIBUTES',
        [ModelType.LINGUISTIC_CONTROLS]: 'LINGUISTIC_CONTROLS',
        [ModelType.RECS_MODEL]: 'RECS_MODEL',
        [ModelType.IMAGE_PATH]: 'IMAGE_PATH',
        [ModelType.SEARCH_DATA_QUALITY]: 'SEARCH_DATA_QUALITY',
        [ModelType.PLACEMENT]: 'PLACEMENT',
        [ModelType.PRODUCT_RECOMMENDATIONS]: 'PRODUCT_RECOMMENDATIONS',
    };
    let ActionType;
    (function (ActionType) {
        ActionType["Create"] = "create";
        ActionType["Save"] = "save";
        ActionType["None"] = "none";
        ActionType["DataTransfer"] = "dataTransfer";
        ActionType["Counter"] = "Counter";
    })(ActionType = SearchForRetail.ActionType || (SearchForRetail.ActionType = {}));
    let PageType;
    (function (PageType) {
        PageType["GLOBAL"] = "global";
        PageType["AREA"] = "area";
        PageType["COLLECTION"] = "collection";
    })(PageType = SearchForRetail.PageType || (SearchForRetail.PageType = {}));
    SearchForRetail.PAGE_TYPE_MAP = {
        [ModelType.RULE]: PageType.AREA,
        [ModelType.BIASING_PROFILE]: PageType.AREA,
        [ModelType.ZONE]: PageType.AREA,
        [ModelType.REDIRECT]: PageType.AREA,
        [ModelType.NAVIGATION]: PageType.AREA,
        [ModelType.ATTRIBUTES]: PageType.COLLECTION,
        [ModelType.LINGUISTIC_CONTROLS]: PageType.AREA,
        [ModelType.PRODUCT_RECOMMENDATIONS]: PageType.COLLECTION,
        [ModelType.FILTER_SETS]: PageType.COLLECTION,
        [ModelType.INGESTION_TASKS]: PageType.GLOBAL,
        [ModelType.USER]: PageType.GLOBAL,
        [ModelType.AREA]: PageType.GLOBAL,
        [ModelType.TEMPLATE]: PageType.GLOBAL,
        [ModelType.TAG]: PageType.GLOBAL,
        [ModelType.ANALYTICS]: PageType.AREA,
        [ModelType.HOME_DASHBOARDS]: PageType.AREA,
        [ModelType.RULE_EXPERIMENTS]: PageType.AREA,
        [ModelType.AUDIT_LOG]: PageType.GLOBAL,
        [ModelType.RELEASE_NOTES]: PageType.GLOBAL,
        [ModelType.EVALUATE]: PageType.AREA,
        [ModelType.SITE]: PageType.COLLECTION,
        [ModelType.AUTOCOMPLETE]: PageType.GLOBAL,
        [ModelType.RECS_MODEL]: PageType.COLLECTION,
        [ModelType.CATEGORIES]: PageType.GLOBAL,
        [ModelType.IMAGE_PATH]: PageType.GLOBAL,
        [ModelType.SEARCH_DATA_QUALITY]: PageType.COLLECTION,
    };
})(SearchForRetail || (SearchForRetail = {}));
export var Migration;
(function (Migration) {
    /** This constant should be used as a value of NavigateOptions state prop of the navigate function to show that previously
     * this navigation call was initiated by a redux-router function but not by a react-router */
    Migration.REDUX_ROUTER_STATE = 'ReduxRouter State';
})(Migration || (Migration = {}));
