import styled from 'styled-components';
import { TableCell, TableRow } from '@mui/material';
import { baseColors, shadows } from '@groupby/ui-components';
export const StyledTableRow = styled(TableRow) `
  && {
    td {
      box-shadow: ${({ $isDropping }) => $isDropping && shadows.dragDropIndicator};
    }


    &.MuiTableRow-hover:hover {
      cursor: pointer;
      background-color: ${baseColors.primaryTeal100} !important;
      td {
        background-color: ${baseColors.primaryTeal100} !important;
      }
    }

    .more-button-icon {
      transform: rotate(90deg);
    }

    &.row--highlighted {
      background-color: ${baseColors.primaryAccentGreen800};
      td {
        background-color: ${baseColors.primaryAccentGreen800} !important;
      }
    }
  }
`;
export const StyledTableCell = styled(TableCell) `
  width: ${({ width }) => width || 'unset'};
  min-width: ${({ width, minWidth }) => minWidth || width || 'unset'};
  max-width: ${({ width, maxWidth }) => maxWidth || width || 'unset'};
  overflow: hidden;
  text-overflow: ellipsis;

  z-index: 1;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'unset')};
  left: ${({ left }) => (typeof left === 'number' ? `${left}px` : 'unset')};
  background-color: ${baseColors.white};
`;
export const StyledDragIcon = styled.span `
    svg {
      path {
        fill: ${baseColors.primaryBlueMed400}
      }
    }
`;
export const StyledTableCellWrapper = styled.div `
    ${({ align }) => align !== 'center' && 'padding-left: 1rem'};
`;
