import { __awaiter } from "tslib";
import { s4rAuthenticated } from '@/client/utils';
import { SearchForRetail } from '@/client/api';
const USER_PATH = 'user';
export const SETTINGS_PATH = 'settings';
export const updateUserSettings = (token, id, settings) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/${USER_PATH}/${id}/${SETTINGS_PATH}`,
        body: settings,
        method: 'PUT',
    });
    return result;
});
