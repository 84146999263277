import { isEnrichOnlyEnv } from '@/utils/environment';
import { isCustomer } from './feature-flags';
export function extractCustomerName(hostname) {
    const [subdomain, secondSubdomain] = hostname.split('.');
    let customerName = '';
    if (subdomain === 'cc' || subdomain === 'ccenrich') {
        // Regular CC environments:
        // cc.${customerName}.groupbycloud.com           - upper CC
        // cc.${customerName}-lo.groupbycloud.com        - lower CC
        // ccenrich.${customerName}.groupbycloud.com     - upper CC (Enrich)
        // ccenrich.${customerName}-lo.groupbycloud.com  - lower CC (Enrich)
        customerName = secondSubdomain.replace(/-lo$/, '');
    }
    else if (isEnrichOnlyEnv(hostname)) {
        // Enrich-only CC environments:
        // ccenrich-${customerName}.enrich.groupbycloud.com     - upper CC (Enrich-only)
        // ccenrich-${customerName}.enrich-lo.groupbycloud.com  - lower CC (Enrich-only)
        const [, ...splitName] = subdomain.split('-');
        customerName = splitName.join('-');
    }
    else {
        // Deprecated environments:
        // ${customerName}.groupbycloud.com            - prod Classic
        // ${customerName}.dev.groupbycloud.com        - dev Classic
        // ${customerName}-atlas.groupbycloud.com      - prod Atlas
        // ${customerName}-atlas.dev.groupbycloud.com  - dev Atlas
        // ${customerName}-cors.groupbycloud.com       - prod CORS
        // ${customerName}-cors.dev.groupbycloud.com   - dev CORS
        customerName = subdomain.replace(/-(?:atlas|cors)$/, '');
    }
    return customerName;
}
export const hasEnrichAccessOnly = isEnrichOnlyEnv() || isCustomer('enrich');
