import loadable from '@/components/loadable';
import { ModelType } from '@/constants';
export const Managers = {
    [ModelType.HOME_DASHBOARDS]: loadable(() => import('./home-dashboards' /* webpackChunkName: "s4r-home-dashboards-manager" */)),
    [ModelType.AREA]: loadable(() => import('./area' /* webpackChunkName: "s4r-area-manager" */)),
    [ModelType.USER]: loadable(() => import('./user' /* webpackChunkName: "s4r-user-manager" */)),
    [ModelType.TAG]: loadable(() => import('./tag' /* webpackChunkName: "s4r-tag-manager" */)),
    [ModelType.ANALYTICS]: loadable(() => import('./analytics/analytics.manager' /* webpackChunkName: "s4r-analytics-manager" */)),
    [ModelType.BIASING_PROFILE]: loadable(() => import('./biasing-strategy' /* webpackChunkName: "s4r-biasing-strategy-manager" */)),
    [ModelType.NAVIGATION]: loadable(() => import('./dynamic-navigation' /* webpackChunkName: "s4r-dynamic-navigation-manager" */)),
    [ModelType.REDIRECT]: loadable(() => import('./redirect' /* webpackChunkName: "s4r-redirect-manager" */)),
    [ModelType.RULE]: loadable(() => import('./rule' /* webpackChunkName: "s4r-rule-manager" */)),
    [ModelType.AUDIT_LOG]: loadable(() => import('./audit-log' /* webpackChunkName: "s4r-audit-log-manager" */)),
    [ModelType.ZONE]: loadable(() => import('./zone' /* webpackChunkName: "s4r-zone-manager" */)),
    [ModelType.TEMPLATE]: loadable(() => import('./template' /* webpackChunkName: "s4r-template-manager" */)),
    [ModelType.PREVIEW_WRAPPER]: loadable(() => import('./preview-wrapper' /* webpackChunkName: "s4r-preview-wrapper-manager" */)),
    [ModelType.INGESTION_TASKS]: loadable(() => import('./upload-tracker' /* webpackChunkName: "s4r-upload-tracker-manager" */)),
    [ModelType.FILTER_SETS]: loadable(() => import('./filter-sets' /* webpackChunkName: "s4r-filter-sets-manager" */)),
    [ModelType.ATTRIBUTES]: loadable(() => import('./attributes' /* webpackChunkName: "s4r-attributes-manager" */)),
    [ModelType.LINGUISTIC_CONTROLS]: loadable(() => import('./linguistic-controls/linguistic-controls.manager' /* webpackChunkName: "s4r-linguistic-controls-manager" */)),
    [ModelType.EVALUATE]: loadable(() => import('./evaluate/evaluate.manager' /* webpackChunkName: "s4r-evaluate-manager" */)),
    [ModelType.PRODUCT_RECOMMENDATIONS]: loadable(() => import('./product-recommendations' /* webpackChunkName: "s4r-product-recommendations-manager" */)),
    [ModelType.SITE]: loadable(() => import('./site' /* webpackChunkName: "s4r-site-manager" */)),
    [ModelType.AUTOCOMPLETE]: loadable(() => import('./autocomplete' /* webpackChunkName: "s4r-autocomplete-manager" */)),
    [ModelType.RECS_MODEL]: loadable(() => import('./recs-model' /* webpackChunkName: "s4r-recs-model-manager" */)),
    [ModelType.CATEGORIES]: loadable(() => import('./categories' /* webpackChunkName: "s4r-categoriesß-manager" */)),
    [ModelType.IMAGE_PATH]: loadable(() => import('./image-path' /* webpackChunkName: "s4r-image-path-manager" */)),
    [ModelType.SEARCH_DATA_QUALITY]: loadable(() => import('./data-quality' /* webpackChunkName: "s4r-data-quality-manager" */)),
    [ModelType.PIPELINE_HEALTH]: loadable(() => import('./pipeline-health' /* webpackChunkName: "s4r-site-health-manager" */)),
};
export default Managers;
